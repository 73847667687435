import React, { useEffect, useState } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { fetchApiRoot } from "./api";
import { Typography } from "@material-ui/core";
import TopBar from './TopBar/index';
import { getPlayer, reloadPlayer } from "./player";
import useInterval from "react-useinterval";
import LeftSidebar from './LeftSidebar';
import "./translations/i18n";
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import i18n from "i18next";
import IframeResizer from 'iframe-resizer-react';

const GameArea = ({ frameLang }) => {
  const player = useSelector(getPlayer);
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const handleIframeMessage = (message) => {
    if (message === 'scroll_to_top') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  window.addEventListener("message", (event) => {
    handleIframeMessage(event.data);
  }, false);


  // Poll wallet when game is open
  useInterval(() => {
    dispatch(reloadPlayer());
  }, player ? 5000 : null);

  if (player) {
    const gameUrl = `${process.env["REACT_APP_GAME_URL"]}?operatorId=FADEMO_OPERATOR&operatorPlayerId=${player.id}&operatorToken=${player.token}&lang=${frameLang}`;

    return (<IframeResizer
        title="game"
        className={'max-width'}
        scolling="no"
        heightCalculationMethod={'documentElementScroll'}
        frameBorder={0}
        src={gameUrl}/>
    );
  } else {
    return (
      <div className="logged-out-div">
        <Typography style={{ marginTop: '2rem' }} variant={'h1'}>{t("log_in_to_play")}</Typography>
      </div>
    );
  }
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApiRoot());
  });

  const [showMenu, setShowMenu] = useState(window.screen.width >= 1212);

  const searchParams = new URLSearchParams(window.location.search);
  const lang = searchParams.get('lang') ? searchParams.get('lang') : i18n.languages[0];
  i18n.changeLanguage(lang);

  return (
    <div className="wrapper">
      <TopBar searchParams={searchParams} selectedLanguage={lang} setShowMenu={setShowMenu} showMenu={showMenu}/>
      <div className="side-bars">
        <Router>
          <Switch>
            <Route path={"/"}>
              <div className="game-flex-div">
                {showMenu ? <LeftSidebar/> : <div/>}
                <GameArea frameLang={lang} />
              </div>
            </Route>
          </Switch>
        </Router>
      </div>
      <Footer searchParams={searchParams} selectedLanguage={lang} />
    </div>
  );
}

export default App;
