import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";

export default function PopupDialog({open, onClose, onOk = () => undefined, title, children, cancelLabel = "Cancel", okLabel = "Ok"}) {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose(); }} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={() => { onClose(); onOk(); }} color="primary">
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}