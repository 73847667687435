import React from "react";
import styles from "./LangSelector.module.css";

const LangSelector = ({searchParams, selectedLanguage}) => {
    return (
            <div>
                <div className={styles.flexCenter}>
                    <select className={styles.select} onChange={(e) => {
                        searchParams.set("lang", e.target.value);
                        window.location.search = searchParams.toString();
                    }}>
                        <option value="sv" selected={selectedLanguage === "sv"}>Swedish</option>
                        <option value="en" selected={selectedLanguage === "en"}>English</option>
                        <option value="fa" selected={selectedLanguage === "fa"}>فارسی</option>
                    </select>
                </div>
            </div>
    );
}

export default LangSelector;