import React from 'react';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.css';
import { AppleIcon, AndroidIcon } from '../icons';
import LangSelector from "../common/LangSelector";
import MediaQuery from "react-responsive/src";

export default function Footer({ selectedLanguage, searchParams }) {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <div>
        <MediaQuery maxWidth={641}>
          <div className={styles.mobileLangSelectWrapper}>
            <LangSelector selectedLanguage={selectedLanguage} searchParams={searchParams}/>
          </div>
        </MediaQuery>

        <div className={styles.downloadDiv}>
          <div className={styles.iconFlex}>
            <AppleIcon />
            <AndroidIcon />
          </div>
          <span className={styles.gray}>{t('download_app')}</span>
        </div>
        <span className={styles.gray}>{t('re_captcha')}</span>
        <div className={styles.spacer} />

        <div className={styles.infoFlex}>
          <span className={styles.link}>{t('about_us')}</span>
          <span className={styles.link}>{t('careers')}</span>
          <span className={styles.link}>{t('how_to_play')}</span>
          <span className={styles.link}>{t('blog')}</span>
          <span className={styles.link}>{t('help_center')}</span>
        </div>

        <div className={styles.disclaimerFlex}>
          <span className={styles.link}>{t('terms_and_conditions')}</span>
          <span className={styles.link}>{t('cookie_policy')}</span>
          <span className={styles.link}>{t('privacy_policy')}</span>
          <span className={styles.link}>{t('game_rules')}</span>
        </div>
      </div>
      <div className={styles.helpDiv}>
        <span>{t('need_help')}</span>
        <span className={styles.smallText}>{t('visit_our_help_center')} <span className={styles.underline}>{t('help_center')}</span></span>
      </div>
    </footer>
  );
}