import React from "react";
import Moment from "react-moment";

const Transactions = ({transactions}) =>
  <div>
    {transactions.map(t =>
      <div style={{display: 'flex'}}>
        <div style={{width: '6em'}}>{t.transactionType}</div>
        <div style={{width: '12em'}}><Moment fromNow>{t.createdAt}</Moment></div>
        <div style={{flexGrow: 1}}>{t.amount}</div>
      </div>
    )}
  </div>;

export default Transactions;
