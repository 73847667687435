import React, { useState } from 'react';
import PopupDialog from "./common/PopupDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { PlusIcon } from './icons';

export default ({ onDeposit, isIcon }) => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const { t } = useTranslation();

  return (
    <span>
      <PopupDialog okLabel={t("deposit_now")} onClose={() => setOpen(false)} onOk={() => onDeposit({ amount })} title={t("deposit")} open={open}>
        <DialogContentText>
          {t("deposit_and_play")}
        </DialogContentText>
        <TextField
          value={amount}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              setOpen(false);
              onDeposit({ amount })
            }
          }}
          onChange={e => setAmount(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label={t("amount")}
          type="numeric"
          fullWidth
        />
      </PopupDialog>
      {isIcon ? <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setOpen(true)}><PlusIcon  /></div> : <span onClick={() => setOpen(true)} style={{ cursor: 'pointer', fontSize: 14, opacity: .5, }}>{t("deposit")}</span>}
    </span>
  );
}